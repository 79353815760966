<template>
<div>
<v-card>
	<v-toolbar flat color="white">
                    <v-toolbar-title>Buscar</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    >
                    </v-divider>
                    <v-spacer></v-spacer>
                    
                    
                    
                    <v-text-field
                        v-model="searchCuenta"
                        append-icon="mdi-magnify"
                        label="Cuenta"
                        single-line                    
                        class='mt-3'
                        @click:append=cargarListaCuentas(true)
                    ></v-text-field>
    </v-toolbar>

	<v-toolbar flat color="white">
		<v-btn  color="primary" small  class="ma-2 white--text" 
		@click="abrirFormCuenta()"
		>
			<v-icon left >mdi-plus-box-outline</v-icon>
		Nueva cuenta
		</v-btn>
	</v-toolbar>

<v-data-table
            :items="dtListaCuentas"
			:headers= "headers"                        
            :options.sync="options"
            :loading="loadingTable"
            loading-text="Cargando registros..."
          	searchable
          	:search="searchCuenta"
            class="elevation-1"
            :items-per-page=50
            dense >
             <template v-slot:item.opcion="{ item }">
                <v-btn 
                  
                   icon 
                   color='red' 
                   target="_blank" 
                   @click= "cargarCuenta(item.gl_account_id)"
                >
                    <v-icon color='orange'>mdi-table-edit</v-icon>
                </v-btn>
               
            </template>
            </v-data-table>
</v-card>

 <v-dialog
      v-model="formCuenta"
      persistent
      max-width="500px"
    >
    
    <v-card>
        <v-card-title>
          <span class="headline">Cuenta Contable</span>
        </v-card-title>
        
    
    
     <v-card-text>
          <v-container>
          <v-form ref="formCuenta">
          
            <v-row class="pt-2 pb-2">
              <v-col class="pt-0 pb-0"
                cols="12"
                sm="10"
                md="10" >
               
               
               <v-autocomplete
               	  v-model="datosCuenta.gl_account_type_id"
                  small
                  dense
                  item-text="description"
          		  item-value="gl_account_type_id"
                  :items="tiposCuentas"
                  label="Tipo de cuenta"
                  :rules="[tipoCuentaRule]"
                  
                ></v-autocomplete>
		</v-col>
		
		</v-row>
       <v-row class="pt-2 pb-2">
			                
		<v-col class="pt-0 pb-0">                
                <v-autocomplete
               	  v-model="datosCuenta.gl_account_class_id"
                  small
                  dense
                  item-text="description"
          		  item-value="gl_account_class_id"
                  :items="clasesCuentas"
                  label="Clase de cuenta"
                  :rules="[glAccountClassRule]"
                ></v-autocomplete>
                
              </v-col>
        </v-row>
        
        <v-row class="pt-2 pb-2">
           <v-col class="pt-0 pb-0">
              <v-autocomplete
               	  v-model="datosCuenta.parent_gl_account_id"
                  small
                  dense
                  item-text="codigo_nombre"
          		  item-value="gl_account_id"
                  :items="cuentasPadre"
                  label="Cuenta padre"
                  :rules="[cuentaPadreRule]"
                ></v-autocomplete>
                
              </v-col>
		</v-row>
		
		<v-row class="pt-2 pb-2">        
              <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">

                <v-text-field
                  v-model="datosCuenta.account_code"
                  label="Codigo de cuenta"
                  type="text"
                  small
                  dense
                ></v-text-field>               
              </v-col>
              
          </v-row>
          
          <v-row class="pt-2 pb-2">
              <v-col cols="12"  sm="8" md="8" class="pt-0 pb-0">
                <v-text-field
                  v-model="datosCuenta.account_name"
                  label="Nombre de cuenta"
                  type="text"
                  small
                  dense                  
                ></v-text-field>
              </v-col>
            <v-row>
            
            </v-row class="pt-2 pb-2">  
              <v-col cols="12"  sm="8" md="8" class="pt-0 pb-0">
                <v-text-field
                  v-model="datosCuenta.description"
                  label="Descripción"
                  type="text"
                  small
                  dense                  
                ></v-text-field>
              </v-col>
               
            </v-row>
            </v-form>
          </v-container>
          
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="formCuenta = !formCuenta"
          >
            Cancelar
          </v-btn>
          <v-btn            
            color="blue darken-1"
            solid
            class = "white--text"
            @click="guardarCuenta()"
          >
            Guardar
          </v-btn>
          
          
        </v-card-actions>
     </v-card>
</v-dialog>


</div>
</template>


<script>
import {mapState, mapMutations, mapActions} from 'vuex'

export default {
    name:'ListaCuentasComponent',
    components:{
        
    },
    data: ()=> ({
    	
    	headers:[
    		{text: 'Opcion', align: 'middle', value: 'opcion', width : 50},
            {text: 'ID', align: 'start', value: 'gl_account_id', width : 80},
            {text: 'Codigo', align: 'start', value: 'account_code'},
            {text: 'Nombre', align: 'start', value: 'account_name'},
            {text: 'Clase', align: 'start', value: 'gl_account_class_id'}
        ],
    	dtListaCuentas: [],
    	options: {},
    	overlay: false,
    	searchCuenta: "",
    	datosCuenta: {},
    	formCuenta: false,
    	tiposCuentas: [],
    	clasesCuentas: [],
    	cuentasPadre: [],
    	refRule:                  
            v => !!v || 'El campo es requerido'
        
    	 
    }),
    computed: {
    	...mapState('master',['loadingTable']),
    	...mapState('contabilidad',['menu'])
    }
   	,methods: {
	   	 ...mapMutations('master',['setUrl','setOverlay','setMenu','setLoadingTable','setTitleToolbar']),
	   	 
	     ...mapActions('master',['requestApi']),
   		
   		cargarListaCuentas(filtrar){
            this.dataTable=[]
            this.setLoadingTable(true)
            this.setUrl('plan-cuentas')
            this.requestApi({
                method : 'GET',
                data :{
                    
                }
            }).then(res=>{
                /** 
                if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                } **/
                this.dtListaCuentas  = res.data._embedded.plan_cuentas
                this.setLoadingTable(false)
                //this.totalItems= parseInt(data.totalFilas.split(",").join(""))
                //this.loadingTableQuotations = false
                //this.resume = data.resumen
            })
        },
        cargarClasesCuentas(){
            
            this.setLoadingTable(true)
            this.setUrl('gl-account-class')
            this.requestApi({
                method : 'GET',
                data :{
                    
                }
            }).then(res=>{
                
                this.clasesCuentas  = res.data._embedded.gl_account_class
                this.setLoadingTable(false)
                
            })
        },
        cargarTiposCuentas() {
        	this.setLoadingTable(true)
            this.setUrl('gl-account-type')
            this.requestApi({
                method : 'GET',
                data :{
                    
                }
            }).then(res=>{
                
                this.tiposCuentas  = res.data._embedded.gl_account_type
                this.setLoadingTable(false)
                
            })
        },
        
        cargarCuentas() {
        	this.setLoadingTable(true)
            this.setUrl('plan-cuentas')
            this.requestApi({
                method : 'GET',
                data :{
                    
                }
            }).then(res=>{
                
                this.cuentasPadre  = res.data._embedded.plan_cuentas
                this.setLoadingTable(false)
                
            })
        },
        
        
        tipoCuentaRule() {
        	return true
        },
        glAccountClassRule() {
        	return true
        },
        cuentaPadreRule(){
        	return  true	
        },
        abrirFormCuenta() {
        	this.datosCuenta = {}
        	this.formCuenta = true	
        },
        guardarCuenta() {
        	if(this.datosCuenta.gl_account_id != "" && this.datosCuenta.gl_account_id != null ) {
        		this.setLoadingTable(true)
                this.setUrl('plan-cuentas/'+this.datosCuenta.gl_account_id)
                this.requestApi({
                    method : 'PATCH',
                    data : this.datosCuenta
                }).then(res=>{
                                    
                    this.cargarListaCuentas()
                    this.setLoadingTable(false)
                    
                })
        		
        	} else {
	        	this.setLoadingTable(true)
	            this.setUrl('plan-cuentas')
	            this.requestApi({
	                method : 'POST',
	                data : this.datosCuenta
	            }).then(res=>{
	                                
	                this.cargarListaCuentas()
	                this.setLoadingTable(false)
	                
	            });
        	}
            
        	this.formCuenta = false
        	
        },
        
		cargarCuenta(cuentaId) {
        	
        	
            this.setUrl('plan-cuentas/'+cuentaId)
            this.requestApi({
                method : 'GET',
                data : this.datosCuenta
            }).then(res=>{
            	console.log(res.data)                    
                this.datosCuenta = res.data
            })
            
        	this.formCuenta = true
        	
        },
   	},
   	
    watch: {
    	
    	options: {
            handler () {
                this.cargarListaCuentas()
                this.cargarTiposCuentas()
                this.cargarClasesCuentas()
                this.cargarCuentas()
                
            },
            deep: true,
        },
    },
    mounted(){
        //this.setMenu(this.menu)
        this.setTitleToolbar('Cuentas contables')
    }
    
    
}
</script>